import { useCallback, useEffect, useState } from 'react'

export const getQuietHoursData = async (boardId: string) => {
  try {
    const response = await fetch(`/board/${boardId}/quiethours`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const data = await response.json()
    return {
      formattedQuietHours: data.formattedQuietHours,
      isQuietHours: data.isQuietHours,
    }
  } catch (e) {
    return {
      formattedQuietHours: '',
      isQuietHours: false,
    }
  }
}

export const useIsQuietHours = ({
  boardId,
}: {
  boardId?: string | null | undefined
}) => {
  const [isQuietHours, setIsQuietHours] = useState(false)
  const [formattedQuietHours, setFormattedQuietHours] = useState('')

  const checkQuietHours = useCallback(async () => {
    if (boardId) {
      const data = await getQuietHoursData(boardId)
      setIsQuietHours(data.isQuietHours)
      setFormattedQuietHours(data.formattedQuietHours)
    }
  }, [boardId])

  useEffect(() => {
    // check on load
    checkQuietHours()
  }, [checkQuietHours, boardId])

  useEffect(() => {
    // check every minute
    const interval = setInterval(() => {
      checkQuietHours()
    }, 60000)
    return () => {
      clearInterval(interval)
    }
  }, [checkQuietHours])

  return { isQuietHours, formattedQuietHours }
}
