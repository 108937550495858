import React from "react";
import {
  SubTitle,
  Spacer,
  Progress,
  Button,
  Body,
  VestaboardContextProvider,
  useVestaboardSubscriptionConfigInformation,
  ActionButton,
  Modal,
  BoardPreview,
  Divider,
  Medium,
  Times,
  MultiTimeSelect,
  ToastProvider,
  Toast,
  Small,
  Theme,
  NotificationBanner,
} from "@vestaboard/installables";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BoardPreviews from "./lib/boards";
import { useIsQuietHours } from "./hooks/useQuietHours";

function App() {
  return (
    <Theme>
      <VestaboardContextProvider>
        <ToastProvider>
          <Router>
            <Routes>
              <Route path="/" element={<DefaultConfig />} />
              <Route path="/settings" element={<DefaultConfig />} />
            </Routes>
          </Router>
        </ToastProvider>
      </VestaboardContextProvider>
    </Theme>
  );
}

const DefaultConfig = () => {
  const [previewOpen, setPreviewOpen] = React.useState(false);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [sendNowSuccessOpen, setSendNowSuccessOpen] = React.useState(false);
  const [failureOpen, setFailureOpen] = React.useState(false);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [qhError, setQhError] = React.useState(false);
  const [sendNowLoading, setSendNowLoading] = React.useState(false);
  const { data, loading } = useVestaboardSubscriptionConfigInformation();
  const boardId = data?.subscription?.board[0]?.id || "";
  const { isQuietHours, formattedQuietHours } = useIsQuietHours({ boardId });

  const [times, setTimes] = React.useState<Times[]>([]);
  // let prevTimes = usePrevious<Times[]>(times);

  function handlePreview() {
    setPreviewOpen(true);
  }

  function handleSubmit() {
    setSubmitLoading(true);
    const jsonData = {
      times: times,
      subscription: data,
    };

    fetch(`${window.location.origin.toString()}/update`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(jsonData),
    })
      .then((response) => response.json())
      .then((data) => {
        setSubmitLoading(false);
        setSuccessOpen(true);
      })
      .catch((error) => {
        console.log(error);
        setSubmitLoading(false);
        setFailureOpen(true);
      });
  }

  function handleSendNow() {
    if (isQuietHours) {
      setQhError(true);
      return
    }
    if (sendNowLoading) {
      return;
    }
    setSendNowLoading(true);
    const jsonData = {
      subscription: data,
    };

    fetch(`${window.location.origin.toString()}/send-now`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(jsonData),
    })
      .then((response) => response.json())
      .then((_) => {
        setSendNowLoading(false);
        setSendNowSuccessOpen(true);
      })
      .catch((error) => {
        console.log(error);
        setSendNowLoading(false);
        setFailureOpen(true);
      });
  }

  React.useEffect(() => {
    if (!data?.subscription?.id) return;
    fetch(
      `${window.location.origin.toString()}/get-times?subscriptionID=${
        data?.subscription?.id
      }&timezone=${data?.subscription?.board[0]?.timezone?.city}`
    )
      .then((response) => response.json())
      .then((data) => {
        setTimes(
          data?.data?.times.map((time: any, idx: any) => {
            // for some reason, the vestaboard components only allow two digit numbers as times
            if (time.hour.length === 1) time.hour = `0${time.hour}`;
            if (time.minutes.length === 1) time.minutes = `0${time.minutes}`;
            return {
              index: idx,
              label: `Time`,
              time,
            };
          })
        );
      })
      .catch(() => {});
  }, [data]);

  // React.useEffect(() => {
  //   const deleteTime = (hour: string, minutes: string, amPm: string) => {
  //     fetch(`${window.location.origin.toString()}/delete-time`, {
  //       method: "DELETE",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         hour: hour,
  //         minutes: minutes,
  //         amPm: amPm,
  //         subscriptionID: data?.subscription?.id,
  //         timezone: data?.subscription.board[0].timezone.city,
  //       }),
  //     })
  //       .then((response) => response.json())
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //   };

  //   if (!times || !prevTimes) return;
  //   // compare the old times array to the new one
  //   // if the new array has fewer elements, then a time was deleted
  //   // therefore, we can loop through compare them to find the deleted element
  //   if (times.length < prevTimes.length) {
  //     for (const prevTime of prevTimes) {
  //       let exists = false;

  //       // perform a linear search to find which time was deleted
  //       for (const time of times) {
  //         if (
  //           prevTime.time.hour === time.time.hour &&
  //           prevTime.time.minutes === time.time.minutes &&
  //           prevTime.time.amPm === time.time.amPm
  //         ) {
  //           exists = true;
  //           break;
  //         }
  //       }

  //       if (!exists) {
  //         deleteTime(
  //           prevTime.time.hour,
  //           prevTime.time.minutes,
  //           prevTime.time.amPm
  //         );
  //         break;
  //       }
  //     }
  //   }

  //   return () => {
  //     prevTimes = times;
  //   };
  // }, [times]);

  if (loading) {
    return (
      <div className="settings">
        <Progress />
      </div>
    );
  } else if (!data) {
    return (
      <div className="settings">
        <SubTitle>Please Wait...</SubTitle>
        <Body>Your subscription config token cannot be found.</Body>
      </div>
    );
  } else if (!data?.subscription?.board[0]?.timezone) {
    return (
      <div>
        <SubTitle>Oops, there's a problem.</SubTitle>
        <Body>
          Please navigate to the settings page to set your timezone to continue.
        </Body>
      </div>
    );
  }

  return (
    <div className="settings">
      <NotificationBanner text={formattedQuietHours} visible={isQuietHours} />
      <SubTitle>Description</SubTitle>
      <Spacer size="large" />
      <Body>
        Quiz your own vocabulary with words taken from the SAT word list!
      </Body>
      <Spacer size="large" />
      <div
        style={{
          display: "flex",
          gap: "0.4rem",
        }}
      >
        <ActionButton
          buttonType="default"
          type="button"
          onClick={() => handlePreview()}
        >
          Preview
        </ActionButton>
        <ActionButton
          buttonType="default"
          type="button"
          onClick={() => handleSendNow()}
        >
          Display Now
        </ActionButton>
      </div>
      <Spacer size="large" />
      <Divider />
      <Spacer size="large" />
      <div>
        <SubTitle>Schedule Send</SubTitle>
        <Spacer size="large" />
        <Medium>
          Automatically send new vocab quizzes to your board at specified times
          each day.
        </Medium>
        <Spacer size="large" />
        <MultiTimeSelect onChange={setTimes} maxTimes={100} value={times} />
        <Small>
          Your timezone is set to{" "}
          {data?.subscription?.board[0]?.timezone?.city.replaceAll("_", " ")}.
          If this is incorrect, adjust your timezone in Settings.
        </Small>
      </div>
      <Spacer size="large" />
      <Divider />
      <Spacer size="large" />
      {!submitLoading ? (
        <Button onClick={() => handleSubmit()} buttonType="white">
          Save Changes
        </Button>
      ) : (
        <div
          style={{
            background: "transparent",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Progress />
        </div>
      )}

      <Modal
        visible={previewOpen}
        transparent={true}
        fullScreenMobile={true}
        onClose={() => setPreviewOpen(false)}
      >
        <BoardPreview characters={BoardPreviews[0]} />
        <Spacer size="large" />
        <BoardPreview characters={BoardPreviews[1]} />
      </Modal>
      <Toast
        severity="success"
        message="Your settings were saved successfully!"
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
      />
      <Toast
        severity="success"
        message="Sent a vocab quiz successfully!"
        open={sendNowSuccessOpen}
        onClose={() => setSendNowSuccessOpen(false)}
      />
      <Toast
        severity="error"
        message={formattedQuietHours}
        open={qhError}
        onClose={() => setQhError(false)}
      />
      <Toast
        severity="error"
        message="Oops, something went wrong..."
        open={failureOpen}
        onClose={() => setFailureOpen(false)}
      />
    </div>
  );
};

export default App;
