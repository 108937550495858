import { IBoard } from "@vestaboard/installables";

const BoardPreviews: IBoard[] = [
  [
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [
      4, 15, 0, 25, 15, 21, 0, 11, 14, 15, 23, 0, 20, 8, 9, 19, 0, 23, 15, 18,
      4, 60,
    ],
    [
      67, 63, 67, 63, 67, 63, 67, 63, 67, 63, 67, 63, 67, 63, 67, 63, 67, 63,
      67, 63, 67, 63,
    ],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 1, 13, 2, 9, 7, 21, 15, 21, 19, 0, 41, 1, 56, 42, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  ],
  [
    [
      67, 63, 0, 0, 1, 13, 2, 9, 7, 21, 15, 21, 19, 0, 41, 1, 56, 42, 0, 0, 67,
      63,
    ],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 21, 14, 3, 12, 5, 1, 18, 0, 15, 6, 0, 9, 14, 5, 24, 1, 3, 20, 0, 0, 0],
    [0, 2, 5, 3, 1, 21, 19, 5, 0, 1, 0, 3, 8, 15, 9, 3, 5, 0, 0, 0, 0, 0],
    [0, 1, 12, 20, 5, 18, 14, 1, 20, 9, 22, 5, 19, 0, 8, 1, 19, 0, 0, 0, 0, 0],
    [0, 14, 15, 20, 0, 2, 5, 5, 14, 0, 13, 1, 4, 5, 56, 0, 0, 0, 0, 0, 0, 0],
  ],
];

export default BoardPreviews;